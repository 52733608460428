import ng from 'angular';
import acpCardHistoryTemplate from './templates/acp-card-history.html';

export class AcpCardHistoryComponentCtrl
  implements nsUtils.NsComponentController {
  constructor(nsComponentDecorator, private nsInPageFlow: any) {
    'ngInject';

    nsComponentDecorator(this, this);
  }

  openHistoryDashboard() {
    this.nsInPageFlow.open({
      flowTitle: 'history',
      template: '<acp-card-history-dashboard></acp-card-history-dashboard>'
    });
  }
}

export const acpCardHistoryComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpCardHistoryComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardHistoryTemplate
};
