import { AcpApptentivePlugin } from 'components/apptentive-domain';
import acpCardPinChangeSecurityDialogTemplate from './templates/acp-card-pin-change-security-dialog.html';

export class AcpCardPinChangeComponentCtrl
  implements nsUtils.NsComponentController {
  public cardId: string;
  public flowTitle;
  public iscvcAuth: boolean;
  public newPin: number;
  public submitting: boolean = false;
  public setPinForm: any;

  constructor(
    nsComponentDecorator,
    private acpCardModel,
    private acpCoreDispatcher,
    private acpVirtualCardsModel,
    private nsInPageFlow,
    private acpApptentivePlugin: AcpApptentivePlugin
  ) {
    'ngInject';

    nsComponentDecorator(this, this);
  }

  public $onInit() {
    this.iscvcAuth = this.acpVirtualCardsModel.isCvcAuthed();
    this.$onValue(
      this.acpCoreDispatcher.authenticate.isCvcUpdated,
      this.getAuthorization.bind(this)
    );

    try {
      this.acpApptentivePlugin.sendEvent('Change_card_pin');
    } catch (error) {
      this.acpApptentivePlugin.errorLog('AcpCardPinChangeComponentCtrl', error);
    }
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  public async handleSubmit() {
    try {
      await this.acpCardModel.changePin(this.cardId, this.newPin);
      this.nsInPageFlow.pop();
      this.acpCoreDispatcher.toast.show.emit({
        toastClass: 'success',
        content: 'pin-change-toast:components/card-detail',
        hideDelay: 4000
      });

      try {
        this.acpApptentivePlugin.sendEvent('Change_pin_succes');
      } catch (error) {
        this.acpApptentivePlugin.errorLog(
          'AcpCardPinChangeComponentCtrl',
          error
        );
      }
    } catch (err) {
      this.acpCoreDispatcher.toast.show.emit({
        toastClass: 'failure errormsg',
        content: 'something-wrong:components/card-detail',
        hideDelay: 4000
      });
    } finally {
      this.submitting = false;
    }
  }

  public isSubmitting() {
    return this.submitting;
  }

  public async changePinSubmit() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    this.handleSubmit();
  }

  public cancel(): void {
    this.nsInPageFlow.close();
  }

  private getAuthorization(data): void {
    this.iscvcAuth = this.acpVirtualCardsModel.isCvcAuthed(data);
  }
}

export const acpCardPinChangeComponent: ng.IComponentOptions = {
  bindings: {
    cardId: '@'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpCardPinChangeComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardPinChangeSecurityDialogTemplate
};
