import ng from 'angular';
import { acpDigitalWalletDomainModule } from 'apps/digital-wallet';
import {
  acpDigitalWalletContent,
  acpDigitalWalletModule
} from 'apps/digital-wallet/components/digital-wallet';

import acpCardDomainModule from 'components/card-domain';
import { acpOrderCardModule } from 'components/order-card';
import { acpReportCardLostStolenModule } from 'components/report-card-lost-stolen';
import acpVirtualCardsLandingModule from 'components/virtual-cards-landing';
import acpCore from 'core';
import iframeOnload from 'shared/angularjs/directives/iframe-on-load';
import acpStopTouchEventDirective from 'shared/angularjs/directives/stop-touch-event';
import acpUnsafeHtmlFilter from 'shared/angularjs/filters/acp-unsafe-html';
import {
  acpCardDetailComponent,
  AcpCardDetailComponentCtrl
} from './acp-card-detail-component';
import { acpCardHistoryDashboardComponent } from './acp-card-history-dashboard-component';
import { AcpCardHistoryDashboardModel } from './acp-card-history-dashboard-model';
import { acpCardHistoryComponent } from './acp-card-history.component';
import {
  acpCardLandingComponent,
  AcpCardLandingComponentCtrl
} from './acp-card-landing-component';
import { acpCardLockComponent } from './acp-card-lock-component';
import acpCardPauseSvgDirective from './acp-card-pause-svg-directive';
import { acpCardPinChangeComponent } from './acp-card-pin-change-component';
import acpCardDetailContent from './card-detail-content.yml';
import acpCardHistoryDashboardContent from './card-history-dashboard-content.yml';
import acpCardHistoryContent from './card-history-content.yml';
import acpCardLandingContent from './card-landing-content.yml';
import acpCardLockContent from './card-lock-content.yml';
import cipDocumentDialogContent from './cip-document-dialog-content.yml';
import './styles/${theme}/core.scss';
import { acpComponentApptentiveDomain } from 'components/apptentive-domain';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.card-landing
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-card-landing></acp-card-landing>
 * ```
 *
 * ```js
 * import {acpCardLandingModule} from 'components/acpCardLanding';
 *
 * angular.module('acp.component.card-landing.example', [
 *     acpCardLandingModule.name
 * ]);
 * ```
 */

export const acpCardLandingModule = ng
  .module('acp.component.card-landing', [
    acpCore.name,
    acpStopTouchEventDirective.name,
    acpUnsafeHtmlFilter.name,
    acpCardDomainModule.name,
    acpOrderCardModule.name,
    acpReportCardLostStolenModule.name,
    'netspend.legos.progressbar',
    acpVirtualCardsLandingModule.name,
    acpDigitalWalletModule.name,
    acpDigitalWalletDomainModule.name,
    iframeOnload.name,
    acpComponentApptentiveDomain.name
  ])
  .component('acpCardLanding', acpCardLandingComponent)
  .component('acpCardDetail', acpCardDetailComponent)
  .component('acpCardPinChange', acpCardPinChangeComponent)
  .component('acpCardHistoryDashboard', acpCardHistoryDashboardComponent)
  .component('acpCardLock', acpCardLockComponent)
  .component('acpCardHistory', acpCardHistoryComponent)
  .directive('acpCardPauseSvg', acpCardPauseSvgDirective)
  .service('acpCardHistoryDashboardModel', AcpCardHistoryDashboardModel)
  .run((contentSectionCache) => {
    'ngInject';

    contentSectionCache.put('components/card-landing', acpCardLandingContent);
    contentSectionCache.put('components/card-detail', acpCardDetailContent);
    contentSectionCache.put(
      'components/digital-wallet',
      acpDigitalWalletContent
    );
    contentSectionCache.put(
      'components/card-pin-change-security-dialog',
      acpCardDetailContent
    );
    contentSectionCache.put(
      'components/card-history-dashboard',
      acpCardHistoryDashboardContent
    );
    contentSectionCache.put('components/card-history', acpCardHistoryContent);
    contentSectionCache.put('components/card-lock', acpCardLockContent);
    contentSectionCache.put(
      'components/cip-document-dialog',
      cipDocumentDialogContent
    );
  });

// Re-export services / ctrls that may be imported from other modules to use for typing information
export { AcpCardLandingComponentCtrl, AcpCardDetailComponentCtrl };
