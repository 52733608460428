import ng from 'angular';
import { AcpApptentivePlugin } from 'components/apptentive-domain';
import {
  AcpCardModel,
  AcpOrderCardModel,
  AcpProgressBarModel,
  CardImage,
  CardResponse,
  OrderCardStep
} from 'components/card-domain';
import { AcpCardHistoryDashboardModel } from './acp-card-history-dashboard-model';
import acpCardLandingTemplate from './templates/acp-card-landing.html';
import cipDocumentNotUploadedDialogTemplet from './templates/cip-document-not-uploaded-dialog.html';
import cipDocumentVerificationDialogTemplet from './templates/cip-document-verification-dialog.html';

export class AcpCardLandingComponentCtrl
  implements nsUtils.NsComponentController {
  public cards: CardResponse[];
  public activeCards: CardResponse[];
  public flowTitle: string;
  public orderCardFlowTitle: string;
  public cardConstant: any;
  public inactiveCards: CardResponse[];
  public isOrderCardEnabled: boolean = false;
  public isOptionalPersoCardFeatureEnabled: boolean = false;
  public isCustomCard: boolean = false;
  public isCustomCardUnforklifted: boolean;
  public isVirtualDebitCardsAllowed: boolean;
  public isSmartOneUser: boolean;
  public isCDDAEligibleForOrderCard: boolean;
  public loading: boolean = true;
  private steps: OrderCardStep[];
  public isCIPConditionalDocumentsNotUploaded: boolean;
  public isCIPConditionalDocumentsUploaded: boolean;

  constructor(
    nsComponentDecorator,
    private acpCardModel: AcpCardModel,
    private nsInPageFlow: any,
    private content: any,
    private ACP_CARD_CONSTANTS: any,
    private acpOrderCardModel: AcpOrderCardModel,
    private acpProgressBarModel: AcpProgressBarModel,
    private acpCoreDispatcher: any,
    private nsPermissions: nsUtils.NsPermissionsService,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private acpCardHistoryDashboardModel: AcpCardHistoryDashboardModel,
    private nsDate: nsUtils.NsDateService,
    private $mdDialog: ng.material.IDialogService,
    private $window: ng.IWindowService,
    private acpApptentivePlugin: AcpApptentivePlugin
  ) {
    'ngInject';

    nsComponentDecorator(this, this);
  }

  public async $onInit() {
    this.cardConstant = this.ACP_CARD_CONSTANTS;
    this.handleListeners();
    await this.requestPermissions();
    this.fetchYmlContent();
    this.cardsInitialization(false).then(() => {
      if (this.$stateParams.showDetail) {
        const filteredCards = this.cards.filter(
          (card) => card.status === this.ACP_CARD_CONSTANTS.CARD_TYPES.ACTIVATED
        );
        if (filteredCards.length === 1) {
          this.showDetail(filteredCards[0]);
        }
      }
    });
    this.acpProgressBarModel.getSteps().then((steps) => (this.steps = steps));
    try {
      this.acpApptentivePlugin.sendEvent('Debit_cards_home');
    } catch (error) {
      this.acpApptentivePlugin.errorLog(
        'AcpSelectCardOwnerComponentCtrl',
        error
      );
    }
  }

  public CanOrderAdditionalCard(): boolean {
    if (this.isOptionalPersoCardFeatureEnabled) {
      return this.isOrderCardEnabled;
    }
    let canOrderCard = false;
    for (const card of this.cards) {
      if (card.hasOwnProperty('activation_date')) {
        canOrderCard = true;
        break;
      }
    }
    return canOrderCard;
  }

  public showDetail(card: CardResponse): void {
    if (card.status === this.cardConstant.CARD_TYPES.TO_BE_ACTIVATED) {
      this.startActivationFlow(card);
      return;
    }
    this.nsInPageFlow.open({
      flowTitle: this.flowTitle,
      template:
        '<div id="card-detail-landing"><acp-card-detail issuer-number="' +
        card.issuer_number +
        '" card-type="' +
        card.status +
        '" card-id="' +
        card.id +
        '"></acp-card-detail></div>'
    });
  }

  public cipDialog(): void {
    const vm = this;
    const controllerPopUp = /*@ngInject*/ function ($scope) {
      $scope.vm = vm;
    };
    if (this.isCIPConditionalDocumentsNotUploaded) {
      this.$mdDialog.show({
        template: cipDocumentNotUploadedDialogTemplet,
        clickOutsideToClose: true,
        controller: controllerPopUp
      });
    } else if (this.isCIPConditionalDocumentsUploaded) {
      this.$mdDialog.show({
        template: cipDocumentVerificationDialogTemplet,
        clickOutsideToClose: true,
        controller: controllerPopUp
      });
    } else {
      this.orderCard();
    }
  }

  public cipAdditionalPage(): void {
    this.$window.location.assign('/account/dashboard/cip-additional-page');
    this.$mdDialog.hide();
  }

  public orderCard(): void {
    this.acpOrderCardModel.reset();
    this.nsInPageFlow.open({
      flowTitle: this.orderCardFlowTitle,
      template: this.steps[this.acpProgressBarModel.getCurrentStep()].component,
      onBackFunction: () => this.acpProgressBarModel.back()
    });
  }

  public isOrderCardOptionAvailable(): boolean {
    if (this.isOptionalPersoCardFeatureEnabled) {
      return (
        (!this.activeCards ||
          this.activeCards.length < this.cardConstant.MAX_ALLOWED_CARD) &&
        this.isOrderCardEnabled
      );
    }
    return (
      this.activeCards &&
      this.activeCards.length < this.cardConstant.MAX_ALLOWED_CARD &&
      this.isOrderCardEnabled
    );
  }

  public getImage(image: CardImage): string {
    return this.acpCardModel.getImage(image);
  }

  public getImageWidth(image: CardImage): string {
    if (image && image.width) {
      return this.acpCardModel.getImageWidth(image.width.split(','));
    }
    return '0';
  }

  public getExpectedArrival(date: string): string {
    const expDateSplit = date.split(' ')[0];
    // TODO: sprajapati, is this the correct logic you are wanting? TS does not like it, so I added `as any` casts.
    // `Date + number = string`
    return new Date(
      (this.nsDate.fromApi(expDateSplit) as any) +
        1000 * 3600 * 24 * this.ACP_CARD_CONSTANTS.EXPECTED_ARRIVAL_MAX_DAYS
    ) as any;
  }

  public isCardLocked(status): boolean {
    return status === this.ACP_CARD_CONSTANTS.CARD_TYPES.LOCKED;
  }

  public isVerticalImage(image: CardImage): boolean {
    return this.acpCardModel.isImageVertical(image);
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  private async cardsInitialization(needCached: boolean) {
    this.cards = await this.acpCardModel.getCards(needCached);
    this.activeCards = this.acpCardModel
      .getActiveCards(this.cards)
      .slice(0, this.cardConstant.MAX_ALLOWED_CARD);
    const allCards = await this.acpCardModel.getAllCards(needCached);
    this.inactiveCards = this.acpCardModel.getInactiveCards(allCards);
    this.acpCardHistoryDashboardModel.resetHistory();
    this.loading = false;
  }

  private updatePermissions(permissions?: nsUtils.NsPermissionResult): void {
    this.isOrderCardEnabled = permissions && permissions.hasOrderCard;
    this.isOptionalPersoCardFeatureEnabled =
      permissions && permissions.isOptionalPersoCardFeatureEnabled;
    this.isCustomCard = permissions && permissions.hasCustomCard;
    this.isCustomCardUnforklifted =
      permissions && permissions.isOac4CustomCardEnabled;
    this.isVirtualDebitCardsAllowed =
      permissions && permissions.hasVirtualDebitCardsAllowed;
    this.acpCardModel.setIsVirtualCardAllowed(this.isVirtualDebitCardsAllowed);
    this.isSmartOneUser = permissions && permissions.isSmartOneUser;
    this.isCDDAEligibleForOrderCard =
      permissions && permissions.isCDDAEligibleForOrderCard;
    this.isCIPConditionalDocumentsNotUploaded =
      permissions && permissions.isCIPConditionalDocumentsNotUploaded;
    this.isCIPConditionalDocumentsUploaded =
      permissions && permissions.isCIPConditionalDocumentsUploaded;
  }

  private startActivationFlow(card: CardResponse) {
    this.$state.go('activate-additional', { cardId: card.id });
  }

  private handleListeners() {
    this.$onValue(this.acpCoreDispatcher.virtualCard.virtualCardsUpdate, () => {
      this.acpCardModel.getAllCards(false).then((cards) => {
        this.inactiveCards = this.acpCardModel.getInactiveCards(cards);
      });
    });
    this.$onValue(this.acpCoreDispatcher.card.cardsUpdate, () => {
      this.acpCoreDispatcher.card.cardsUpdateSuccess.emit();
      this.requestPermissions();
      this.cardsInitialization(true).then(ng.noop);
    });
    this.$onValue(this.acpCoreDispatcher.virtualCard.virtualCardApiFail, () => {
      this.loading = false;
    });
  }

  private requestPermissions(): Promise<nsUtils.NsPermissionResult> {
    return this.nsPermissions
      .requestPermissions([
        'hasVirtualDebitCardsAllowed',
        'hasOrderCard',
        'hasCustomCard',
        'isOac4CustomCardEnabled',
        'isSmartOneUser',
        'isCDDAEligibleForOrderCard',
        'isOptionalPersoCardFeatureEnabled',
        'isCIPConditionalDocumentsNotUploaded',
        'isCIPConditionalDocumentsUploaded'
      ])
      .then(this.updatePermissions.bind(this));
  }

  private fetchYmlContent() {
    this.content
      .getKey('card-flow-title', 'components/card-landing')
      .then((data) => (this.flowTitle = data.copy));
    this.content
      .getKey('order-another-card', 'components/card-landing')
      .then((data) => (this.orderCardFlowTitle = data.copy));
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
}

export const acpCardLandingComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpCardLandingComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardLandingTemplate
};
