import { AcpCardModel, CardImage, CardResponse } from 'components/card-domain';
import { AcpCardHistoryDashboardModel } from './acp-card-history-dashboard-model';
import acpCardHistoryDashboardTemplate from './templates/acp-card-history-dashboard.html';

export class AcpCardHistoryDashboardComponentCtrl
  implements nsUtils.NsComponentController {
  public inactiveCards: CardResponse[];
  public filterType: string;
  public acpCardConstant;

  constructor(
    nsComponentDecorator,
    private nsInPageFlow: any,
    private ACP_CARD_CONSTANTS: any,
    private acpCardHistoryDashboardModel: AcpCardHistoryDashboardModel,
    private acpCoreDispatcher: any,
    private acpCardModel: AcpCardModel
  ) {
    'ngInject';

    // If methods such as $onValue and $tie are needed:
    nsComponentDecorator(this, this);
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  public $onInit(): void {
    this.acpCardConstant = this.ACP_CARD_CONSTANTS;
    this.setListCategory(this.acpCardConstant.INACTIVE_ALL_CARDS);
    this.$onValue(this.acpCoreDispatcher.virtualCard.virtualCardsUpdate, () => {
      this.setListCategory(this.filterType);
    });
  }

  public async setListCategory(type: string) {
    this.inactiveCards = await this.getInactiveTypedCards(type);
    this.filterType = type;
  }

  public isNamedFilterActive(type: string): boolean {
    if (!type) {
      throw new Error('Must provide a type for isNamedFilterActive');
    }
    return this.filterType === type;
  }

  public showCardDetails(index: number): void {
    const card = this.inactiveCards[index];
    let template = `<acp-virtual-card-detail card-type='inactive' id='${
      card.id
    }'></acp-virtual-card-detail>`;

    if (card.category === this.acpCardConstant.CARD_CATEGORY.PHYSICAL) {
      template = `<acp-card-detail card-type='inactive' card-id='${
        card.id
      }'></acp-card-detail>`;
    }

    this.nsInPageFlow.push({
      template
    });
  }

  public isVisaCard(card: CardResponse): boolean {
    return card.association === this.acpCardConstant.CARD_ASSOCIATION.VISA_CARD;
  }

  public isMasterCard(card: CardResponse): boolean {
    if (card.category === this.acpCardConstant.CARD_CATEGORY.PHYSICAL) {
      // ToDo: remove this block of code when association key is included in Physical cards.
      return true;
    }

    return (
      card.association === this.acpCardConstant.CARD_ASSOCIATION.MASTER_CARD
    );
  }

  public getIsVirtualCardAllowed(): boolean {
    return this.acpCardModel.getIsVirtualCardAllowed();
  }

  public getImage(image: CardImage): string {
    return this.acpCardModel.getImage(image);
  }

  public getImageWidth(image: CardImage): string {
    if (image && image.width) {
      return this.acpCardModel.getImageWidth(image.width.split(','));
    }
    return '0';
  }

  public isVerticalImage(image: CardImage): boolean {
    return this.acpCardModel.isImageVertical(image);
  }

  private async getInactiveTypedCards(type: string): Promise<CardResponse[]> {
    let cards;
    switch (type) {
      case this.acpCardConstant.INACTIVE_PHYSICAL_CARDS:
        cards = await this.acpCardHistoryDashboardModel.getInactivePhysicalCards();
        break;
      case this.acpCardConstant.INACTIVE_VIRTUAL_CARDS:
        cards = await this.acpCardHistoryDashboardModel.getInactiveVirtualCards();
        break;
      default:
        cards = await this.acpCardHistoryDashboardModel.getAllInactiveCards();
    }
    return cards;
  }
}

export const acpCardHistoryDashboardComponent: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpCardHistoryDashboardComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardHistoryDashboardTemplate
};
