import { CardResponse } from 'components/card-domain';
import { AcpCardModel } from 'components/card-domain';

export class AcpCardHistoryDashboardModel {
  private cards = this.nsProperty.create<CardResponse[]>();
  constructor(
    private acpCardModel: AcpCardModel,
    private nsUtil: nsUtils.NsUtilService,
    private nsProperty: nsUtils.NsPropertyService,
    private ACP_CARD_CONSTANTS: any,
    private acpCoreDispatcher: any
  ) {
    'ngInject';
    this.acpCoreDispatcher.virtualCard.virtualCardsUpdate.onValue(
      this.resetHistory.bind(this)
    );
    this.acpCoreDispatcher.card.cardsUpdate.onValue(
      this.resetHistory.bind(this)
    );
  }

  public async getInactivePhysicalCards(): Promise<CardResponse[]> {
    const cards = await this.getInactiveCards();
    const physicalCards = cards.filter(
      (card) => card.category === this.ACP_CARD_CONSTANTS.CARD_CATEGORY.PHYSICAL
    );
    physicalCards.sort(this.cardDateComparator);
    return physicalCards;
  }

  public async getInactiveVirtualCards(): Promise<CardResponse[]> {
    const cards = await this.getInactiveCards();
    const virtualCards = cards.filter(
      (card) => card.category === this.ACP_CARD_CONSTANTS.CARD_CATEGORY.VIRTUAL
    );
    virtualCards.sort(this.cardDateComparator);
    return virtualCards;
  }

  public async getAllInactiveCards(): Promise<CardResponse[]> {
    const cards = await this.getInactiveCards();
    cards.sort(this.cardDateComparator);
    return cards;
  }

  public resetHistory(): void {
    this.cards.reset();
  }

  private setInactivationDate(card: CardResponse) {
    let inactivationDate = card.expiration_date;
    if (
      this.nsUtil.isDefined(card.lost_date) &&
      card.lost_date < inactivationDate
    ) {
      inactivationDate = card.lost_date;
    }
    if (
      this.nsUtil.isDefined(card.stolen_date) &&
      card.stolen_date < inactivationDate
    ) {
      inactivationDate = card.stolen_date;
    }
    card.inactivation_date = inactivationDate;
  }

  private cardDateComparator(
    cardOne: CardResponse,
    cardTwo: CardResponse
  ): number {
    // TODO: atripathi, is this the correct logic you are wanting?
    // `Date + Date = string`
    return (
      (cardTwo.inactivation_date as any) - (cardOne.inactivation_date as any)
    );
  }

  private async getInactiveCards(): Promise<CardResponse[]> {
    if (this.cards.getValue()) {
      return this.cards.getValue();
    } else {
      let cards = await this.acpCardModel.getAllCards(false);
      cards = this.acpCardModel.getInactiveCards(cards);
      let it;
      for (it = cards.length - 1; it >= 0; it--) {
        this.setInactivationDate(cards[it]);
      }
      this.cards.set(cards);
      return cards;
    }
  }
}
