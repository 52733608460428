import {
  AcpDigitalWalletDomainModel,
  TokenizedPlatforms
} from 'apps/digital-wallet/components/digital-wallet-domain';
import { AcpApptentivePlugin } from 'components/apptentive-domain';
import { AcpCardModel, CardImage, CardResponse } from 'components/card-domain';
import { AcpDeviceServiceProvider } from 'core';
import acpCardLandingTemplate from './templates/acp-card-detail.html';

export class AcpCardDetailComponentCtrl
  implements nsUtils.NsComponentController {
  card;
  cardConstant;
  flowTitle;
  isCardControlEnabled: boolean;
  permissions = {};
  paymentPlatform: string;
  lockStatus: boolean;
  addedToWallet: TokenizedPlatforms;
  showDigitalWalletButton: boolean;
  isLoading = true;
  isGooglePay: boolean;
  isApplePay: boolean;
  isSamsungPay: boolean;
  isVerticalImage: boolean;
  isActiveWallet = false;
  private isAndroid: boolean = this.acpDeviceService.isAndroid();
  private isIos: boolean = this.acpDeviceService.isIos();
  private cardId;
  private cardType: string;
  private issuerNumber: string;

  constructor(
    nsComponentDecorator,
    private acpCardModel: AcpCardModel,
    private ACP_CARD_CONSTANTS: any,
    private content: any,
    private nsInPageFlow: any,
    private acpDeviceService: AcpDeviceServiceProvider,
    public acpDigitalWalletDomainModel: AcpDigitalWalletDomainModel,
    private $mdDialog: angular.material.IDialogService,
    private nsPermissions: nsUtils.NsPermissionsService,
    private $log: ng.ILogService,
    private $location: ng.ILocationService,
    private $anchorScroll: ng.IAnchorScrollService,
    private $nsExternalAnchor,
    private acpCoreDispatcher: any,
    private $window: ng.IWindowService,
    private acpApptentivePlugin: AcpApptentivePlugin
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
  }

  $tiePermissions?(property: string, permissions: string[]);

  async $onInit() {
    // Perform initialization here
    this.$tiePermissions('permissions', ['isCardControlEnabled']);
    this.handleListeners();
    this.cardConstant = this.ACP_CARD_CONSTANTS;
    this.initializeCardData();
    this.content
      .getKey('change-pin', 'components/card-detail')
      .then((data) => (this.flowTitle = data.copy));

    this.$tiePermissions('permissions', [
      'orderAdditionalCardEligible',
      'isSecondaryCardHolderAllowed',
      'isNewCardHolderAllowed',
      'isDigitalWalletEnabled',
      'isAddResumeEventForGpayStatusCheck',
      'isDigitalIssuanceAccount'
    ]);

    this.isGooglePay = await this.acpDigitalWalletDomainModel.isGooglePay();
    this.isApplePay = await this.acpDigitalWalletDomainModel.isApplePay();
    this.isSamsungPay = await this.acpDigitalWalletDomainModel.isSamsungPay();

    // register cordova's resume listener to update card status
    if (
      (this.permissions as nsUtils.NsPermissionResult)
        .isAddResumeEventForGpayStatusCheck &&
      this.isGooglePay
    ) {
      this.$window.document.addEventListener(
        'resume',
        this.canAddCardToWallet.bind(this)
      );
    }

    try {
      this.acpApptentivePlugin.sendEvent('Debit_card_settings');
    } catch (error) {
      this.acpApptentivePlugin.errorLog('AcpCardDetailComponentCtrl', error);
    }
  }

  activatedOrLockedCard(): boolean {
    return (
      this.cardType === this.cardConstant.CARD_TYPES.ACTIVATED ||
      this.cardType === this.cardConstant.CARD_TYPES.LOCKED
    );
  }

  changePin(): void {
    this.nsInPageFlow.push({
      flowTitle: this.flowTitle,
      template: `<acp-card-pin-change card-id=${this.cardId}></acp-card-pin-change>`
    });
  }

  reportCard(): void {
    this.nsInPageFlow.push({
      flowTitle: ' Report Card Lost/Stolen',
      template: `<acp-report-card issuer-number=${this.issuerNumber} card-id=${this.cardId}></acp-report-card>`
    });
  }

  getImage(image: CardImage): string {
    return this.acpCardModel.getImage(image);
  }

  getImageWidth(image: CardImage): string {
    if (image && image.width) {
      return this.acpCardModel.getImageWidth(image.width.split(','));
    }
    return '0';
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  scroll(): void {
    this.$location.hash('heading');
    this.$anchorScroll();
  }

  lockYourCard() {
    this.showConfirmationDialog();
    this.scroll();
  }

  addToWallet(provider: string): void {
    this.$nsExternalAnchor.showMessage(
      '',
      null,
      null,
      async (result: boolean): Promise<void> => {
        try {
          if (result) {
            await this.acpDigitalWalletDomainModel.addToWallet(
              this.card,
              provider
            );
          }
        } catch (e) {
          this.$log.error(e);
          if (e !== 'user.canceled') {
            this.acpDigitalWalletDomainModel.error(e);
          }
        } finally {
          this.canAddCardToWallet();
        }
      }
    );
  }

  isImageVertical(image: CardImage): boolean {
    return this.acpCardModel.isImageVertical(image);
  }

  private updatePermissions(permissions?: nsUtils.NsPermissionResult): void {
    this.isCardControlEnabled = permissions && permissions.isCardControlEnabled;
  }

  private handleListeners() {
    this.$onValue(this.nsPermissions.permissions, (permissions) =>
      this.updatePermissions(permissions)
    );
    this.$onValue(this.acpCoreDispatcher.card.cardLock, () => {
      this.goToCardLock();
    });
    this.$onValue(this.acpCoreDispatcher.card.cardsUpdate, () => {
      this.initializeCardData();
    });
  }

  private goToCardLock() {
    this.showConfirmationDialog();
  }

  private getObscuredPan(pan: string): string {
    return pan.replace(/XXXX-/g, '&#8226;&#8226;&#8226;&#8226;&nbsp;');
  }

  private initializeCardData() {
    this.acpCardModel.getCard(this.cardId).then((card) => {
      this.card = card;
      this.card.card_number = this.getObscuredPan(
        `XXXX-XXXX-XXXX-${this.card.pan_last_4}`
      );
      this.card.inactive_date = this.getInActivationDate(this.card);
      this.canAddCardToWallet();
      this.isVerticalImage = this.isImageVertical(this.card.images.details);
    });
  }

  private getInActivationDate(card: CardResponse): Date {
    if (card.status === this.cardConstant.CARD_TYPES.LOST_STOLEN) {
      return card.lost_date ? card.lost_date : card.stolen_date;
    }
    return card.expiration_date;
  }

  private modelSuccess(): void {
    this.$mdDialog.cancel();
    this.reportCard();
  }

  private showConfirmationDialog(): void {
    const template = `<acp-card-lock card-id = ${this.cardId} card-type = ${this.card.status}></acp-card-lock>`;
    this.$mdDialog
      .show({
        template,
        clickOutsideToClose: true,
        fullscreen: true
      })
      .then(this.modelSuccess.bind(this));
  }

  showDigitalWalletSection(): boolean {
    return (
      this.showDigitalWalletButton &&
      (this.permissions as nsUtils.NsPermissionResult).isDigitalWalletEnabled &&
      !(this.permissions as nsUtils.NsPermissionResult)
        .isDigitalIssuanceAccount &&
      !this.isLoading
    );
  }

  private async canAddCardToWallet(): Promise<void> {
    try {
      this.addedToWallet = await this.acpDigitalWalletDomainModel.isTokenized(
        this.card
      );
      this.isLoading = true;

      // Check to see if Google Pay plugin is available and if the card is added to Google
      const canAddToGooglePay =
        this.isGooglePay && !this.addedToWallet.googlepay;

      // Check to see if Samsung Pay plugin is available and if the card is added to Samsung
      const canAddToSamsungPay =
        this.isSamsungPay && !this.addedToWallet.samsungpay;

      // Check to see if Apple Pay plugin is available and if the card is added to Apple
      const canAddToApplePay = this.isApplePay && !this.addedToWallet.applepay;

      if (this.isAndroid) {
        // If both Google and Samsung Pay are available but one isn't added to the wallet
        // we want to show the "add to wallet" button
        this.showDigitalWalletButton = canAddToGooglePay || canAddToSamsungPay;
      } else if (this.isIos) {
        this.showDigitalWalletButton = canAddToApplePay;
      }
    } catch (e) {
      this.showDigitalWalletButton = true;
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  }
}

export const acpCardDetailComponent: ng.IComponentOptions = {
  bindings: {
    cardType: '@',
    cardId: '@',
    issuerNumber: '@'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpCardDetailComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardLandingTemplate
};
