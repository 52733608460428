import { AcpApptentivePlugin } from 'components/apptentive-domain';
import { AcpCardModel } from 'components/card-domain';
import acpCardLockTemplate from './templates/acp-card-lock.html';
export class AcpCardLockComponentCtrl implements nsUtils.NsComponentController {
  public cardId: string;
  public cardType: string;
  public serverErrors: string[];
  private toggleSwitch: boolean;
  constructor(
    nsComponentDecorator,
    private $mdDialog,
    private acpCardModel: AcpCardModel,
    private ACP_CARD_CONSTANTS: any,
    private acpApptentivePlugin: AcpApptentivePlugin
  ) {
    'ngInject';

    nsComponentDecorator(this, this);
  }

  public $onInit() {
    // init
    this.toggleSwitch =
      this.cardType === this.ACP_CARD_CONSTANTS.CARD_TYPES.LOCKED;
  }

  public async lockCard() {
    try {
      await this.acpCardModel.cardDeclinedAllTransactions({
        pseudo_pan: this.cardId,
        control: 'DECLINE_ALL', // This is the only value of control for now
        control_value: this.toggleSwitch ? 'DISABLE' : 'ENABLE'
      });
      await this.acpCardModel.resetCards();
      try {
        this.acpApptentivePlugin.sendEvent('Pause_debit_card');
      } catch (error) {
        this.acpApptentivePlugin.errorLog('AcpCardLockComponentCtrl', error);
      }
      this.lockCancel();
    } catch (error) {
      this.serverErrors = error._server_errors;
      this.toggleSwitch =
        this.cardType === this.ACP_CARD_CONSTANTS.CARD_TYPES.LOCKED;
    }
  }

  public lockCancel(): void {
    this.$mdDialog.cancel();
  }

  public toReportLostStolen() {
    this.$mdDialog.hide();
  }
}

export const acpCardLockComponent: ng.IComponentOptions = {
  bindings: {
    cardId: '@',
    cardType: '@'
  },
  controller: AcpCardLockComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpCardLockTemplate
};
