import acpCardPauseSvgTemplate from './templates/acp-card-pause-svg.html';
// @ngInject
function acpCardPauseSvgDirective() {
  return {
    restrict: 'E',
    scope: {},
    template: acpCardPauseSvgTemplate
  };
}

export default acpCardPauseSvgDirective;
